const METHOD = {
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

const PREFIX_IMAGE = '/images'
const PREFIX_DOCUMENT = '/document'

const STATUS_SKIPP_POOLING_SURVEY = [
  {value: "1", name: "Yes"},
  {value: "0", name: "No"}
]
const STATUS_TYPE_POOLING_SURVEY = [
  {value: "0", name: "Single choice"},
  {value: "1", name: "Multiple choice"},
  {value: "2", name: "Scale"},
  {value: "3", name: "Text response"},
  {value: "4", name: "Long text response"}
]
const STATUS = [
  {value: "", name: "All"},
  {value: "2", name: "Draft"},
  {value: "1", name: "Active"},
  {value: "0", name: "Inactive"}
]
const STATUS_COUPON = [
  {value: "", name: "All"},
  {value: "1", name: "Active"},
  {value: "2", name: "Cancelled"},
  {value: "3", name: "Expired"},
  {value: "4", name: "Used"}
]

const STATUS_EXTRA_STAMP_TYPE = [
  {value: '0', name: 'No Extra'},
  {value: '1', name: 'Multiplied'},
  {value: '2', name: 'Per Stamp Unit'}
]

const STATUS_STAMP_HOW_TO_EARN = [
  {value: "", name: "All"},
  {value: "1", name: "Minimal Total Transaksi"},
  {value: "2", name: "Pemebelian Produk Sponsor"},
  {value: "3", name: "Kombinasi"}
]

const STATUS_STAMP = [
  {value: "", name: "All"},
  {value: "10,30", name: "Stamp In"},
  {value: "20,21,31", name: "Stamp Out"}
]

const STATUS_OFFER = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Published - Live"},
  {value: "2", name: "Expired"},
  {value: "3", name: "Inactive"},
  {value: "4", name: "Published - Upcoming"}
]

const STATUS_BANNER = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Published - Live"},
  // {value: "2", name: "Archived"},
  {value: "3", name: "Expired"},
  {value: "4", name: "Inactive"},
  {value: "5", name: "Published - Upcoming"}
]

const STATUS_PUSHMESSAGE = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Pending Approval"},
  {value: "2", name: "Published - Upcoming"},
  {value: "3", name: "Published - Live"},
  {value: "4", name: "Expired"},
  {value: "5", name: "Rejected"},
  {value: "6", name: "Inactive"}
]

const TYPE_OFFER = [
  {value: "0", name: "All Member"},
  {value: "1", name: "App Member"}
]

const TYPE_BANNER = [
  {value: "", name: "All"},
  {value: "0", name: "Promo Item"},
  {value: "1", name: "Promo Catalog"},
  {value: "2", name: "Recipe Text"},
  {value: "3", name: "Recipe Image"},
  {value: "4", name: "Article Text"},
  {value: "5", name: "Article Image"},
  {value: "6", name: "General"}
]

const TYPE_PUSHMESSAGE = [
  {value: "", name: "All"},
  {value: "0", name: "Broadcast"},
  {value: "1", name: "Recurring"}
]

const TYPE_INTERNAL_PAGE = [
  {value: "homepage", name: "Home Page"},
  {value: "articlepage", name: "Article Page"},
  {value: "benefitpage", name: "Benefit Page"},
  {value: "recipepage", name: "Recipe Page"}
]

const POSITION_HIGHLIGHT = [
  {value: "1", name: "1"},
  {value: "2", name: "2"},
  {value: "3", name: "3"},
  {value: "4", name: "4"},
  {value: "5", name: "5"},
  {value: "6", name: "6"},
  {value: "7", name: "7"},
  {value: "8", name: "8"},
  {value: "9", name: "9"},
  {value: "10", name: "10"}
]

const CATEGORY_SORT_BANNER = [
  {value: "1", name: "PERISHABLE"},
  {value: "2", name: "FOOD"},
  {value: "3", name: "NON FOOD"},
  {value: "4", name: "GENERAL MERCHANDISING"},
  {value: "5", name: "COUNTER AND PROMOTION"},
  {value: "6", name: "EQUIPMENT"},
  {value: "7", name: "INDRIRECT PROCUREMENT"}
]

const MOCK_FILTER_ROLE = {
  id: '1', 
  name: 'Offers', 
  view: true, 
  add: true, 
  edit: true,
  delete: true
}

const MOCK_USER_DATA = {
  name: 'John Doe',
  role: 'Administrator'
}

const MOCK_COUPON_HISTORY = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "MSI91123123",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "123123132",
      "name": "Set Of Kitchen",
      "redemption_date": "01/Jun/2023, 00:00:00",
      "coupon_status": "1",
      "store": "Jakarta"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "MSI91123123",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "123123132",
      "name": "Set Of Kitchen",
      "redemption_date": "01/Jun/2023, 00:00:00",
      "coupon_status": "1",
      "store": "Jakarta"
    }
  ]
}

const MOCK_POLLING_FORM = {
  data: [
    {
      id: 1,
      form_id: '001/20/07/2023',
      form_name: 'Survey1',
      description: 'description1',
      created_at: '20/07/2023 20:00:00',
      updated_at: '20/07/2023 20:00:00',
      updated_by: '5984caf7-554b-42c9-b08f-969c90263813',
      status: '1'
    },
    {
      id: 1,
      form_id: '001/20/07/2023',
      form_name: 'Survey1',
      description: 'description1',
      created_at: '20/07/2023 20:00:00',
      updated_at: '20/07/2023 20:00:00',
      updated_by: '5984caf7-554b-42c9-b08f-969c90263813',
      status: '1'
    }
  ],
  meta: {
    page: 1,
    limit: 10,
    total: 1
  }
}
const MOCK_POLLING_FORM_DETAIL = {
  data: {
    form_polling: [
      {
        form_name: 'Survey1',
        start_datetime: '20/07/2023 20:00:00',
        end_datetime: '20/07/2023 20:00:00',
        description: '20/07/2023 20:00:00',
        upload_image: '',
        title_notif: 'Title notif',
        body_notif: 'Body Notif'
      },
      {
        form_name: 'Survey12',
        start_datetime: '20/07/2023 20:00:00',
        end_datetime: '20/07/2023 20:00:00',
        description: '20/07/2023 20:00:00',
        upload_image: '',
        title_notif: 'Title notif',
        body_notif: 'Body Notif'
      }
    ],
    detail: [
      {
        id: 1,
        response_id: '001/20/07/2023',
        response_date: '2023/07/20',
        consumer_name: 'consumer name',
        phone_number: '835535334324',
        email: 'a@email.com',
        app_card_id: '5984caf7-554b-42c9-b08f-969c90263813'
      },
      {
        id: 1,
        response_id: '001/20/07/2023',
        response_date: '2023/07/20',
        consumer_name: 'consumer name',
        phone_number: '835535334324',
        email: 'a@email.com',
        app_card_id: '5984caf7-554b-42c9-b08f-969c90263813'
      }
    ],
    meta: {
      page: 1,
      limit: 10,
      total: 1
    }
  }
}

const MOCK_STAMP_HISTORY = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "start_datetime": "01/Jun/2023, 00:00:00",
      "stamp_in": "2",
      "stamp_out": "19",
      "balance": "34",
      "transaction_id": "1213123213132",
      "coupon_id": "MSI91123123",
      "store": "Jakarta"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "start_datetime": "15/Feb/2023, 00:00:00",
      "stamp_in": "10",
      "stamp_out": "20",
      "balance": "50",
      "transaction_id": "90349535490",
      "coupon_id": "MSI911231239030945",
      "store": "Bekasi"
    }
  ]
}
const MOCK_TOP_PRODUCTS = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "product_sku": "562324234",
      "product_name": "01/Jun/2023, 00:00:00",
      "supplier": "2312321",
      "normal_price": "Set of kitchen",
      "coupon_amount": "Rizki irawan"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "product_sku": "562324234",
      "product_name": "01/Jun/2023, 00:00:00",
      "supplier": "2312321",
      "normal_price": "Set of kitchen",
      "coupon_amount": "Rizki irawan"
    }
  ]
}
const MOCK_DETAIL_COUPON = {
  "id":"afb41125-de88-4520-879d-cffcda1b9e42",
  "coupon_id": "562324234",
  "claimed_date": "01/Jun/2023, 00:00:00",
  "reward_id": "2312321",
  "name": "Set of kitchen",
  "consumer_name": "Rizki irawan",
  "phone_number": "028347234423",
  "email": "rizki@gmail.com",
  "app_card_id": "23312221231123",
  "physical_card_id": "23312221231123",
  "redemption_date": "01/Jun/2023, 23:59:59",
  "expiry_date": "01/Jun/2023, 23:59:59",
  "status": "0",
  "store": "Tebet"
}

const MOCK_COUPONS = 
{
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "562324234",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "2312321",
      "name": "Set of kitchen",
      "consumer_name": "Rizki irawan",
      "phone_number": "028347234423",
      "email": "rizki@gmail.com",
      "app_card_id": "23312221231123",
      "physical_card_id": "23312221231123",
      "redemption_date": "01/Jun/2023, 23:59:59",
      "status": "0",
      "store": "Tebet"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "562324234",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "2312321",
      "name": "Set of kitchen",
      "consumer_name": "Rizki irawan",
      "phone_number": "028347234423",
      "email": "rizki@gmail.com",
      "app_card_id": "23312221231123",
      "physical_card_id": "23312221231123",
      "redemption_date": "01/Jun/2023, 23:59:59",
      "status": "0",
      "store": "Tebet"
    }
  ]
}
const MOCK_HOWTO_REDEEMS = 
{
  "data": [
    {
      "title_bahasa": "My Super Indo App Update 1",
      "title_english": "My Super Indo App  Update"
    },
    {
      "content_bahasa": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
      "content_english": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>"
    }
  ],
  "message": "OK"
}
const MOCK_HOWTO_REDEEM = 
{
  "data": {
    "content_bahasa": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
    "content_english": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
    "title_bahasa": "My Super Indo App Update 1",
    "title_english": "My Super Indo App  Update"
  },
  "message": "OK"
}
const MOCK_REWARD_DETAIL = {
  name: 'Kitchen',
  campaign_id: 'Campaign',
  status: '1',
  promo_code: 'TEST_DETAIL',
  supplier: 'Master Chef EDIT',
  product_reward_price: '30000',
  exchange_stamp: '20 EDIT',
  product_list: [{id: 1, product_name: 'set of kitchen'}, {id: 2, product_name: 'set of kitchen2'}],
  product_normal_price: '100000',
  start_datetime: "2023-06-12 04:38:03",
  end_datetime: "2023-06-22 04:38:03",
  start_datetime_coupon: "2023-06-12 04:38:03",
  end_datetime_coupon: "2023-06-22 04:38:03",
  reward_available: '1',
  reward_repeatable: '1',
  reward_repeatable_limit: '5',
  // number_of_repetition: '4',
  reward_tags: [
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "tag_id": "e3a20bc6-deff-43fa-a5b6-44abffd87b72",
      "tag": {
        "id": "e3a20bc6-deff-43fa-a5b6-44abffd87b72",
        "type": 0,
        "name": "Salman Test Tag",
        "status": 1
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "tag_id": "16607cb0-9155-4c3e-82a9-629dd3c275a0",
      "tag": {
        "id": "16607cb0-9155-4c3e-82a9-629dd3c275a0",
        "type": 1,
        "name": "Age Range 30-34",
        "status": 1
      }
    }
  ],
  reward_detail: [
    {title: 'bahasaINDO',language_type: 0,  description: 'DESCINDO', term: 'termINDO', image_upload: [
      'https://storage.googleapis.com/lsi-loyalty-qa/offer/1678351916409971042-HEAD%20AND%20SOULDERS.jpg',
      'https://storage.googleapis.com/lsi-loyalty-qa/offer/1670993660188423784-morinaca.jpeg', ''
    ]},
    {title: 'bahasaENGLISH', language_type: 1, description: 'DESCENGLISH', term: 'termENG', image_upload: ['https://storage.googleapis.com/lsi-loyalty-qa/offer/1678351916409971042-HEAD%20AND%20SOULDERS.jpg', '', '']}
  ],
  reward_stores: [
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "6",
      "store": {
        "id": "6",
        "name": "CINERE MALL"
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "15",
      "store": {
        "id": "15",
        "name": "DAGO"
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "96",
      "store": {
        "id": "96",
        "name": "DUREN TIGA"
      }
    }
  ]
}
const MOCK_REWARD_PROMO = {
  data: {
    reward_type: '0',
    supplier: 'Master Chef',
    product_reward_price: '30000',
    product_list: [{id: 1, product_name: 'set of kitchen'}, {id: 2, product_name: 'set of kitchen2'}],
    reward_stores: "0008;0021;0119",
    product_normal_price: '100.000',
    start_datetime: '23/Jun/22, 00:00:00',
    end_datetime: '23/Jun/22, 23:59:59',
    reward_available: '1',
    reward_repeatable: '1',
    reward_repeatable_limit: '5',
    start_datetime_coupon: '23/Jun/22, 00:00:00',
    end_datetime_coupon: '23/Jun/22, 23:59:59'
  }
}
const MOCK_REWARD = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "reward_id": "003/03/05/2023",
      "name": "Set of Kitchen",
      "exchange_stamp": "20",
      "reward_price": "20000",
      "start_date": "01/Jun/2023, 00:00:00",
      "end_date": "01/Jun/2023, 23:59:59",
      "created_at": "2023-05-03T06:54:21.503926Z",
      "created_by": "452e2e75-1b87-4036-9d81-57b9ea33cf55",
      "last_updated": "2023-05-03T06:54:21.503926Z",
      "updated_by": "452e2e75-1b87-4036-9d81-57b9ea33cf55",
      "status": "0"
    }
  ],
  "meta": {
    "page": 0,
    "limit": 0,
    "total": 0
  }
}
const MOCK_DETAIL_STAMP_PROMO_MINIMUM = {
  name: "51.000 Stamp",
  promo_code: "MNM111",
  type: "1",
  status: "0",
  minimum_transaction: "50000",
  stamp_earn_minimum_transaction:"5000",
  stamp_multiply: "0",
  start_datetime: "01/Jun/2023, 00:00:00",
  end_datetime: "31/Jun/2023, 00:59:59",
  stamp_excluded_product: "1",
  extra_stamp_type: "2",
  extra_stamp_value: "2",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_DETAIL_STAMP_PROMO_SPONSOR = {
  name: "50.000 Stamp",
  promo_code: "SPN111",
  type: "2",
  status: "0",
  stamp_earn_sponsored_product: "22",
  start_datetime:"01/Jun/2023, 00:00:00",
  end_datetime:"31/Jun/2023, 00:59:59",
  stamp_excluded_product: "1",
  extra_stamp_type: "1",
  extra_stamp_value: "2",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_DETAIL_STAMP_PROMO_KOMBINASI = {
  name:"50.000 Stamp",
  promo_code: "KMB111",
  type: '3',
  status: "0",
  minimum_transaction: '50000',
  stamp_earn_minimum_transaction:"5000",
  stamp_multiply: '0',
  stamp_earn_sponsored_product: "51000",
  stamp_excluded_product: "1",
  start_datetime:"01/Jun/2023, 00:00:00",
  end_datetime:"31/Jun/2023, 00:59:59",
  extra_stamp_type: "0",
  extra_stamp_value: "0",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_STAMP_PROMO_MINIMUM = {
  data: {
    type: '1',
    minimum_transaction: '50000',
    stamp_multiply: '0',
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}
const MOCK_STAMP_PROMO_SPONSOR = {
  data: {
    type: '2',
    stamp_sponsored_products: [
      {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
      {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
      {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
    ],
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}
const MOCK_STAMP_PROMO_KOMBINASI = {
  data: {
    type: '3',
    minimum_transaction: '50000',
    stamp_multiply: '1',
    stamp_sponsored_products: [
      {product_code: 111, product_name: 'Chitatos', product_price: 1500, product_supplier: 'Indofood'},
      {product_code: 222, product_name: 'Chiki Enak', product_price: 30000, product_supplier: 'Indofood'},
      {product_code: 333, product_name: 'Biskuit Roma', product_price: 40000, product_supplier: 'Indofood'}
    ],
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}

export {
  METHOD,
  PREFIX_IMAGE,
  STATUS_OFFER,
  STATUS_BANNER,
  STATUS_PUSHMESSAGE,
  TYPE_OFFER,
  TYPE_BANNER,
  TYPE_PUSHMESSAGE,
  PREFIX_DOCUMENT,
  TYPE_INTERNAL_PAGE,
  POSITION_HIGHLIGHT,
  CATEGORY_SORT_BANNER,
  MOCK_FILTER_ROLE,
  MOCK_USER_DATA,
  STATUS,
  MOCK_DETAIL_STAMP_PROMO_MINIMUM,
  MOCK_DETAIL_STAMP_PROMO_SPONSOR,
  MOCK_DETAIL_STAMP_PROMO_KOMBINASI,
  MOCK_STAMP_PROMO_MINIMUM,
  MOCK_STAMP_PROMO_SPONSOR,
  MOCK_STAMP_PROMO_KOMBINASI,
  MOCK_REWARD,
  MOCK_REWARD_PROMO,
  MOCK_REWARD_DETAIL,
  MOCK_HOWTO_REDEEM,
  MOCK_HOWTO_REDEEMS,
  MOCK_COUPONS,
  MOCK_TOP_PRODUCTS,
  MOCK_DETAIL_COUPON,
  STATUS_STAMP,
  STATUS_COUPON,
  MOCK_STAMP_HISTORY,
  MOCK_COUPON_HISTORY,
  STATUS_STAMP_HOW_TO_EARN,
  STATUS_EXTRA_STAMP_TYPE,
  STATUS_SKIPP_POOLING_SURVEY,
  STATUS_TYPE_POOLING_SURVEY,
  MOCK_POLLING_FORM,
  MOCK_POLLING_FORM_DETAIL
}
