import { apiGetAuth } from '@/utils/api'
import { API_DIGITAL_STAMP } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    coupons: { data: [] },
    top_products: { data: [] },
    detail_coupon: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false,
      loadingColumnTable: false
    }
  }
}

const mutations = {
  SET_COUPONS (state, payload) {
    state.coupons = payload
  },
  SET_TOP_PRODUCTS (state, payload) {
    state.top_products = payload
  },
  SET_DETAIL_COUPON (state, payload) {
    state.detail_coupon = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListCoupon(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.REPORT_COUPON, payload)
      context.commit('SET_COUPONS', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListTopProduct(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.REPORT_PRODUCT, payload)
      context.commit('SET_TOP_PRODUCTS', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailCoupon(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.REPORT_COUPON_DETAIL(payload))
      context.commit('SET_DETAIL_COUPON', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportReportCoupon(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.EXPORT_REPORT_COUPON, payload)
      if (response.data) {
        return true
      }
      return false
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportReportProduct(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.EXPORT_REPORT_PRODUCT, payload)
      if (response.data) {
        return true
      }
      return false
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_coupons (state) {
    return state.coupons
  },
  list_top_products (state) {
    return state.top_products
  },
  detail_coupon (state) {
    return state.detail_coupon
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
