import { createStore } from "vuex"
import mobile from './modules/mobile/reset-password-verification'
import auth from './modules/auth'
import consumer from './modules/consumer/consumer'
import faq from './modules/consumer/faq'
import helpCenter from './modules/consumer/help-center'
import approvalMessage from './modules/consumer/approval-message'
import notifOffer from './modules/campaign/notification-offer'
import campaign from './modules/campaign/campaigns'
import offer from './modules/campaign/offers'
import banner from './modules/campaign/banners'
import pushMessage from './modules/campaign/push-messages'
import role from './modules/user-management/role'
import user from './modules/user-management/user'
import logHistory from './modules/user-management/log-history'
import consumerTag from './modules/master-data/consumer-tag'
import groupStore from './modules/master-data/group-store'
import store from './modules/master-data/store'
import product from './modules/master-data/product'
import privacyPolicy from './modules/master-data/privacy-policy'
import settingApp from './modules/master-data/setting-app'
import termCondition from './modules/master-data/term-condition'
import stamp from './modules/digital-stamp/stamp'
import reward from './modules/digital-stamp/reward'
import howtoRedeem from './modules/digital-stamp/how_to_redeem'
import report from './modules/digital-stamp/report'
import consumerHistory from './modules/digital-stamp/consumer'
import form from './modules/polling-survey/form'
import response from './modules/polling-survey/response'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    mobile,
    auth,
    faq,
    consumer,
    campaign,
    offer,
    role,
    user,
    logHistory,
    groupStore,
    consumerTag,
    store,
    product,
    privacyPolicy,
    termCondition,
    banner,
    helpCenter,
    approvalMessage,
    notifOffer,
    pushMessage,
    settingApp,
    stamp,
    reward,
    howtoRedeem,
    report,
    consumerHistory,
    form,
    response
  }
})
