// import { apiGetAuth } from '@/utils/api'
// import { API_POLLING_SURVEY } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    forms: { data: [] },
    detail_form: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_FORM (state, payload) {
    state.forms = payload
  },
  SET_DETAIL_FORM (state, payload) {
    state.detail_form = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListForm(context, payload) {
    try {
      // const response = await apiGetAuth(API_POLLING_SURVEY.FORM, payload)
      context.commit('SET_FORM', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailForm(context, payload) {
    try {
      // const response = await apiGetAuth(API_POLLING_SURVEY.FORM_DETAIL(payload))
      context.commit('SET_DETAIL_FORM', payload)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_forms (state) {
    return state.forms
  },
  detail_form (state) {
    return state.detail_form
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
