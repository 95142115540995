import { getDataRole } from './auth'
const exportCsv = (dataTarget, targetName) => {
  if (dataTarget) {
    const csvFile = dataTarget
    const tagDownload = document.createElement("a")
    const blob = new Blob(["\ufeff", csvFile])
    const urlTarget = URL.createObjectURL(blob)

    tagDownload.href = urlTarget
    tagDownload.download = targetName + ".csv"

    document.body.appendChild(tagDownload)
    tagDownload.click()
    document.body.removeChild(tagDownload)
  }
  return null
}

const aksesManagement = (dataRole, action, type) => {
  if (dataRole?.length == 0 || dataRole == undefined) {
    if (type == 'component' || type == 'table') {
      return false
    } else {
      return 'red'
    }
  } else {
    if (action) {
      if (type == 'component' || type == 'table') {
        return false
      } 
      if (type == 'class') {
        return 'red'
      }
    } else {
      if (type == 'component' || type == 'table') {
        return true
      } 
      if (type == 'class') {
        return 'disabled'
      }
    }
  }
}

const findDetailUser = (data, param) => {
  if (data) {
    if (data?.length > 0) {
      return data?.find(item => item.id == param)?.username
    }
  }
}

const accessManagement2 = (action, loading) => {
  if (getDataRole().length < 1) return true
  if (!action || loading) return false
  return true
}

const logHistory = (action, menu, id, newData, oldData) => {
  if (action == 'unlocked' || action == 'locked' || action == 'unwithdrawal' || action == 'withdrawal') {
    return `${action} = ${id}, ${newData?.reason || ''}`
  } else if (action == 'linked-card') {
    return `${action} (${id}) = ${newData?.physical_card_id || ''}`
  } else if (action == 'unlinked-card') {
    return `${action} (${id}) = ${oldData?.physical_card_id || ''}`
  } else if (action == 'resend-verification') {
    return `${action} = ${id}`
  } else {
    return action
  }
}

// Get file name image for duplicate
const getFileNameURLImage = (dataImage) => {
  const splitImage = dataImage?.split('/')
  const getExt = splitImage[splitImage.length - 1]?.split('.')

  if (getExt[0].search('duplicate') > 0) {
    const countingFileName = getExt[0]?.split('_')
    if (countingFileName[countingFileName.length - 1] != '') {
      let currenCount = parseInt(countingFileName[countingFileName.length - 1])
      currenCount = currenCount + 1
      return `${countingFileName[0]}_${countingFileName[1]}_${currenCount}.${getExt[getExt.length - 1]}`
    } else {
      return `${getExt[0]}_1.${getExt[getExt.length - 1]}`
    }

  } else {
    return `${getExt[0]}_duplicate_1.${getExt[getExt.length - 1]}`
  }
}

const loopingSelect = (value, currentIndex, data) => {
  if (Object.keys(data.selectID).length > 0) {
    for (let i = 0; i < data.listApiID.length; i++) {
      if (i != currentIndex) {
        const tempValue = data.valueClick
        const findData = data.templateID.find(item => item.id == tempValue)
        if (findData != undefined) {
          const position = data.templateID.indexOf(findData)
          data.listApiID[i].splice(position, 0, findData)
        } 
        data.listApiID[i] = data.listApiID[i].filter(item => item.id != value)
      }
    }
  }
}

const CheckPhoneNumberID = (data, lengths) => {
  return data.phone_number.substring(0, lengths)
}

const truncateData = (data, lengths) => {
  const newTruncate = data.substring(0, lengths)
  if (data.length > lengths) {
    return newTruncate + '...'
  } else {
    return newTruncate
  }
}

const getFileSize = (e, maxSize) => {
  const fileSize =  e.target.files[0].size / 1000 / 1000
  if (fileSize > maxSize) {
    return false
  }
  return true
}

const timeOut = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export {
  exportCsv,
  aksesManagement,
  findDetailUser,
  logHistory,
  getFileNameURLImage,
  loopingSelect,
  CheckPhoneNumberID,
  truncateData,
  getFileSize,
  timeOut,
  accessManagement2
}
