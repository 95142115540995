<template>
  <div
    class="uk-child-width-1-2@s uk-child-width-1-2@m uk-text-center uk-margin-remove"
    uk-grid
    height="100vh"
  >
    <div class="uk-flex uk-flex-column uk-flex-between">
      <div style="margin-top:100px;">
        <div class="box-header-login uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          <img
            :src="`${images}/logo-superindo.png`"
            alt="logo-superindo"
            width="120"
            height="120"
          >
          <h4>Super Indo Dashboard</h4>
        </div>
      </div>
      <div class="uk-margin-large-top">
        <img
          :src="`${images}/bg-login.png`"
          alt="bg-login"
        >
      </div>
    </div>
    <div
      style="background: linear-gradient(#F86B6B 100%, #F04848 100%);padding:120px 110px;"
    >
      <div class="uk-card uk-card-body uk-card-default uk-padding-medium">
        <Form
          v-slot="{ values }"
          :validation-schema="schema"
          @submit="onSubmit"
          @invalid-submit="invalidSubmit"
        >
          <fieldset class="uk-fieldset uk-text-left">
            <legend />
            <h4
              class="uk-legend"
            >
              Login 
            </h4>
            <div class="uk-margin uk-text-left">
              <div class="uk-inline uk-width-1-1">
                <label>Username</label>
                <Field
                  name="username"
                  class="uk-input"
                  type="text"
                  autocomplete="off"
                  placeholder="Input your username"
                  :readonly="otpForm ? true : false"
                  :validate-on-input="true"
                />
                <ErrorMessage 
                  name="username" 
                  as="p"
                  class="error"
                />
              </div>
            </div>
            
            <div class="uk-margin uk-text-left parent-password-form">
              <div class="uk-inline uk-width-1-1">
                <img
                  v-if="isVisible"
                  class="uk-form-icon-custom uk-form-icon-flip"
                  :src="`${images}/icon/ic_eye_on.svg`"
                  alt="eye-on-icon"
                  @click="isVisible = !isVisible"
                >
                <img
                  v-else
                  class="uk-form-icon-custom uk-form-icon-flip"
                  :src="`${images}/icon/ic_eye_off.svg`"
                  alt="eye-off-icon"
                  @click="isVisible = !isVisible"
                >
                <label>Password</label>
                <Field
                  name="password"
                  class="uk-input"
                  :type="isVisible ? 'password' : 'text'"
                  placeholder="Input your password "
                  autocomplete="new-password"
                  :validate-on-input="true"
                />
                <ErrorMessage 
                  name="password" 
                  as="p"
                  class="error"
                />
              </div>

              <div
                v-if="otpForm"
                class="uk-margin uk-text-left"
              >
                <div class="uk-inline uk-width-1-1">
                  <label>Input Your OTP</label> 
                  <Field
                    v-slot="{ field, meta }"
                    name="otp"
                    :validate-on-input="true"
                    :validate-on-blur="false"
                    :hidden="!otpForm"
                  >
                    <input
                      type="text"
                      v-bind="field"
                      class="uk-input"
                      maxlength="6"
                      placeholder="Input your OTP "
                      onkeypress="return /^[0-9]*$/i.test(event.key)"
                      @blur="handleOTP($event, values)"
                      @keypress.enter="handleEnter(values)"
                    >
                    <p v-if="meta.dirty">
                      <ErrorMessage 
                        name="otp" 
                        as="p"
                        class="error"
                      />
                    </p>
                  </Field>
                  <!-- <ErrorMessage 
                    v-show="errorMessage && meta.touched"
                    name="otp" 
                    as="p"
                    class="error"
                  /> -->
                </div>
              </div>
              <div
                v-show="otpForm"
                class="uk-margin-small-top otp-run"
              >
                <vue-countdown
                  v-if="otpForm"
                  v-slot="{ minutes, seconds }"
                  :time="timerCount"
                  @end="onCountdownEnd"
                >
                  <span style="color: black;">{{ minutes < 10 ? `0${minutes}` : minutes }} : {{ seconds < 10 ? `0${seconds}` : seconds }}</span>
                </vue-countdown>
                <!-- {{ countDown }} -->
              </div>
              <button
                v-show="getToggleModalAddEditDiscard.loadingOTP"
                class="uk-button disabled uk-margin-small-top"
              >
                <div uk-spinner />
              </button>
              <button
                v-show="values.password?.length < 8 && isResendOTP"
                class="uk-button red uk-margin-small-top disabled"
                disabled="true"
              >
                Resend OTP
              </button>
              <button
                v-show="!getToggleModalAddEditDiscard.loadingOTP && isResendOTP && timerCount < 1 && values.password?.length > 7"
                class="uk-button red uk-margin-small-top"
              >
                Resend OTP
              </button>
            </div>
            <div class="uk-margin uk-text-right">
              <span @click="$router.push({ name: 'Forgot' })">Forgot Password?</span>
            </div>
            <button
              v-if="isResendOTP"
              class="uk-button uk-width-1-1 disabled"
              disabled="true"
            >
              <span>Login</span>
            </button>
            <button
              v-else-if="!getToggleModalAddEditDiscard.loading"
              class="uk-button uk-width-1-1 red"
              :class="handleLoginVerification(values)?.class"
              :disabled="handleLoginVerification(values)?.disable"
            >
              <span
                :style="handleLoginVerification(values)?.disable ? {cursor: 'default'} : {cursor: 'pointer'}"
              >
                {{ !otpForm ? 'Login' : 'Verification' }}
              </span>
            </button>
            <button
              v-else
              class="uk-button uk-width-1-1 disabled"
              type="button"
              disabled
            >
              <div uk-spinner />
            </button>
          </fieldset>
        </Form>
      </div>
      <footer-default />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '../../utils/constant'
import FooterDefault from '@/components/globals/footer'
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { notificationDanger } from '@/utils/notification'

export default {
  components: {
    FooterDefault,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    const schema = yup.object({
      username: yup.string().min(3).max(30).required('Username is required').label('Username'),
      password: yup.string().min(8).required('Password is required'),
      otp: yup.string().min(6).max(6).required('OTP is required').label('OTP')
    })
    return {
      images: PREFIX_IMAGE,
      is_loading: false,
      isVisible: true,
      otpForm: false,
      submitCount: 0,
      reqBody: {},
      // countDown: null,
      timerCount: null,
      isResendOTP: false,
      schema
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'auth/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/login',
      verificationLogin: 'auth/verificationLogin'
    }),
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    onCountdownEnd() {
      this.otpForm = false
      this.submitCount = 0
      this.isResendOTP = true
      this.timerCount = 0
      // this.countDown = null
    },
    handleOTP(e, values) {
      const value = e.target.value
      const regex = /[0-9]/g
      const inputOTP = [...value.matchAll(regex)].join('')
      values.otp = inputOTP

    },
    handleLoginVerification(values) {
      if (!this.otpForm) {
        if (this.getToggleModalAddEditDiscard.loading || (values && (values.username && values.password?.length > 7))) {
          return {
            class: 'red',
            disable: false
          }
        } else {
          return {
            class: 'disabled',
            disable: true
          }
        }
      }
      if (this.otpForm && values.otp && values.otp.length == 6 && values.password.length < 7) {
        return {
          class: 'disabled',
          disable: true
        }
      } else if (this.otpForm && values.otp && values.otp.length == 6 && values.password.length > 7) {
        return {
          class: 'red',
          disable: false
        }
      } else {
        return {
          class: 'disabled',
          disable: true
        }
      }
      
    },
    invalidSubmit({ values }) {
      if (values && (values.username && values.password?.length > 7 )) {
        if (!this.isResendOTP) {
          if (!this.otpForm) {
            const param = {
              username: values.username, 
              password: values.password
            }
            this.hanldleLoadingType(param, 'login')
          }
        } else {
          const param2 = {
            username: values.username, 
            password: values.password
          }
          this.hanldleLoadingType(param2, 'otp')
        }
      } else {
        return false
      }
      
    },
    hanldleLoadingType(values, param) {
      if (param == 'login') {
        this.setToggleModalAddEditDiscard({loading:true})
        setTimeout(() => {
          this.login(values)
          this.setToggleModalAddEditDiscard({loading:false})
        }, 1000)
      } else {
        this.setToggleModalAddEditDiscard({loadingOTP:true})
        setTimeout(() => {
          this.login(values)
          this.setToggleModalAddEditDiscard({loadingOTP:false})
        }, 1000)
      }
    },
    onSubmit(values) {
      this.verificationOTP(values)
    },
    async login(values) {
      const response = await this.loginUser(values)
      if (response?.data?.role?.name == "Super Admin") {
        this.otpForm = true
        this.isResendOTP = false
        // this.timerCount = 300
        this.timerCount = 3 * 100 * 1000
      }
    },
    async verificationOTP(values) {
      this.setToggleModalAddEditDiscard({loading:true})
      setTimeout(async () => {
        const response = await this.verificationLogin(values)
        if (response == undefined) {
          // this.timerCount = null
          this.isResendOTP = false
          // this.countDown = null
        } else {
          notificationDanger(response)
        }
        this.setToggleModalAddEditDiscard({loading:false})
      }, 2000)
    },
    handleEnter(values) {
      if (!values.password || !values.otp || values.password.length < 8 || values.otp.length < 6) {
        return
      }
      this.verificationOTP(values)
    }
  }
}
</script>

<style scoped>
  .uk-card.uk-card-body.uk-card-default {
    border-radius: 24px;
  }
  .uk-margin.uk-text-left {
    margin-top: 24px !important;
  }
  h4 {
    margin-top: 8px;
    font-weight: 700;
    font-size: 24px !important;
    color: #000 !important;
  }
  .uk-legend {
    margin-bottom: 0;
  }
  label {
    display: inline-block;
    margin-bottom: 8px;
    font-family: 'jakartaRegular';
    font-size: 16px !important;
    color: #22252F !important;
  }
  .uk-margin.uk-text-right span {
    color: #E93535;
    font-size: 14px;
  }
  .uk-margin.uk-text-right span, .uk-button.red span{
    font-family: 'jakartaSemiBold';
    font-weight: 600;
    cursor: pointer;
  }
  .uk-button.red span {
    font-size: 16px;
  }
  .uk-button {
    display: flex;
    justify-content: center;
  }
  .parent-password-form {
    position: relative;
  }
  .otp-run {
    position: absolute;
    bottom: -30px;
    left: 2px;
  }
</style>
