import { apiGetAuth } from '@/utils/api'
import { API_DIGITAL_STAMP } from '@/utils/api-url'
import { exportCsv } from '@/utils/helper'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    stamp_history: { data: [] },
    coupon_history: { data: [] },
    detail_coupon_history: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_STAMP_HISTORY (state, payload) {
    state.stamp_history = payload
  },
  SET_COUPON_HISTORY (state, payload) {
    state.coupon_history = payload
  },
  SET_DETAIL_COUPON_HISTORY (state, payload) {
    state.detail_coupon_history = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListStampHistory(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.CONSUMER_STAMP_HISTORY, payload)
      context.commit('SET_STAMP_HISTORY', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListCouponHistory(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.CONSUMER_COUPON_HISTORY, payload)
      context.commit('SET_COUPON_HISTORY', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailCouponHistory(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.CONSUMER_COUPON_HISTORY_DETAIL(payload))
      context.commit('SET_DETAIL_COUPON', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportConsumerStampHistory(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.EXPORT_CONSUMER_STAMP_HISTORY, payload)
      exportCsv(response.data, "export_consumer_stamp_history")
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportConsumerCouponHistory(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.EXPORT_CONSUMER_COUPON_HISTORY, payload)
      exportCsv(response.data, "export_consumer_coupon_history")
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_stamp_history (state) {
    return state.stamp_history
  },
  list_coupon_history (state) {
    return state.coupon_history
  },
  detail_coupon_history (state) {
    return state.detail_coupon_history
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
